import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      margin: "2em 0",
      minHeight: 670
    },
    infoSection: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      marginBottom: "3rem",
      [theme.breakpoints.down(700)]: {
        flexDirection: "column-reverse"
      }
    },
    author: {
      transition: "all .3s ease",
      [theme.breakpoints.down(700)]: {
        fontSize: "2rem"
      }
    },
    title: {
      margin: "0.5rem 0",
      fontSize: "1.5rem",
      transition: "all .3s ease",
      [theme.breakpoints.down(700)]: {
        fontSize: "1.3rem"
      }
    },
    icons: {
      color: theme.palette.secondary.main,
      margin: "0 .5rem"
    },
    image: {
      borderRadius: 50,
      overflow: "hidden",
      width: "35%",
      marginLeft: "auto",
      transition: "all .3s ease",
      [theme.breakpoints.down(700)]: {
        margin: "0 auto 1rem",
        width: "100%",
        maxWidth: 300
      }
    },
    typography: {
      marginBottom: "1rem"
    },
    skillsImageContainer: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    header: {
      width: "60%",
      transition: "all .3s ease",
      [theme.breakpoints.down(700)]: {
        width: "100%",
        textAlign: "center"
      }
    },
    summary: {
      fontSize: "1.15rem",
      transition: "all .3s ease",
      [theme.breakpoints.down(450)]: {
        fontSize: ".8rem"
      }
    },
    footer: {
      marginTop: "60px"
    }
  })
)
