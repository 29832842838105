import React from "react"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import useStyles from "../styles/home"

import Layout from "../components/layout"
import Image from "../components/image"
import SkillImage from "../components/SkillImage"
import SEO from "../components/seo"
import Fade from "../components/Fade"

interface Props {
  uri: string
  data: any
}

const stack = [
  "gatsby",
  "react",
  "contentful",
  "typescript",
  "material.ui",
  "graphql"
]

const IndexPage: React.FC<Props> = props => {
  const paragraph = props.data.allContentfulSummary.edges[0].node.body.content
  const { author, github, linkedin, email } = props.data.site.siteMetadata
  const c = useStyles()
  return (
    <Layout uri={props.uri}>
      <SEO title="Home" />
      <main className={c.main}>
        <Fade>
          <section className={c.infoSection}>
            <div className={c.header}>
              <Typography className={c.author} variant="h3" color="secondary">
                {author}
              </Typography>
              <Typography className={c.title} variant="body1" color="secondary">
                Full stack web developer
              </Typography>
              <Box>
                <Tooltip title="LinkedIN">
                  <a href={linkedin} target="_blank" rel="noopener">
                    <FontAwesomeIcon
                      className={c.icons}
                      size="2x"
                      icon={faLinkedinIn}
                    />
                  </a>
                </Tooltip>

                <Tooltip title="Github">
                  <a href={github} target="_blank" rel="noopener">
                    <FontAwesomeIcon
                      className={c.icons}
                      size="2x"
                      icon={faGithub}
                    />
                  </a>
                </Tooltip>
              </Box>
            </div>
            <div className={c.image}>
              <Image name="home" />
            </div>
          </section>

          <section>
            <Typography className={c.typography} variant="h5" color="primary">
              about myself
            </Typography>
            {paragraph.map((p: any, i: any) => (
              <Typography
                className={c.summary}
                variant="body1"
                align="justify"
                key={i}
                color="secondary"
              >
                {p.content[0].value}
              </Typography>
            ))}
          </section>

          <footer className={c.footer}>
            <div className={c.skillsImageContainer}>
              {stack.map(s => (
                <SkillImage
                  key={s}
                  width="50px"
                  custom={{ flexGrow: 1, margin: ".5rem" }}
                  name={s}
                />
              ))}
            </div>
          </footer>
        </Fade>
      </main>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query fetchContentSummary {
    allContentfulSummary {
      edges {
        node {
          body {
            content {
              nodeType
              content {
                value
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        author
        linkedin
        github
        email
      }
    }
  }
`
